import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendationHome from "../components/recommendation-home"

const Index = ({ data, location }) => {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const dressAll = shuffleArray(data.dressAll.edges)
  const dressBlack = shuffleArray(data.dressBlack.edges)
  const dressWhite = shuffleArray(data.dressWhite.edges)
  const dressPink = shuffleArray(data.dressPink.edges)
  const dressRed = shuffleArray(data.dressRed.edges)
  const dressGreen = shuffleArray(data.dressGreen.edges)
  // const dressBridesmaid = shuffleArray(data.dressBridesmaid.edges)
  // const dressSales = shuffleArray(data.dressSales.edges)

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Shop Formal Dress - Newest and hottest dress - your ultimate dress destination.  </title>
        <link rel="canonical" href="https://www.shopformaldress.com/" />      
        <meta name="description" content="Skip the endless search for the latest dress styles across numerous stores with Shop Formal Dress. Our platform combines the trendiest designs from various sources into one convenient stop for all your dress desires. Get the newest, hottest dress styles effortlessly with us."/>
  
  <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Shop Formal Dress",
              "url": "https://www.shopformaldress.com",
              "logo": "https://www.shopformaldress.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.shopformaldress.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": "You'll find the biggest and best selection of women's dresses in Shop Formal dress. Every week, we add and refresh new styles in our collections. We aggregate the newest and hottest dresses from different sources,  so you don't need to spend countless hours browsing different stores  and boutiques to discover it."
            }
        `}
        </script>
      </Helmet>
      <div>
      <h1 class="home-intro">Are you in search of the perfect formal or cocktail dress for that special occasion? <br/> <br/>Are you finding it challenging to navigate through numerous stores and boutiques to find a dress that suits your style and needs? <br/> <br/>The task can be daunting, especially when you spend countless hours without finding what you truly desire.</h1>
      <h2 class="home-intro">We present a collection of the latest and most fashionable dress styles from a variety sources. Our aim is to make your shopping experience enjoyable and less time-consuming. With our vast selections, we are your one-stop-shop for all your dress needs.</h2>
      <RecommendationHome data={dressAll} name="New" collection="new-dresses"/>
      <RecommendationHome data={dressBlack} name="Black" collection="black-dresses"/>
      <RecommendationHome data={dressWhite} name="White" collection="white-dresses"/>
      <RecommendationHome data={dressPink} name="Pink" collection="pink-dresses"/>
      <RecommendationHome data={dressRed} name="Red" collection="red-dresses"/>
      <RecommendationHome data={dressGreen} name="Green" collection="green-dresses"/>      
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    dressAll: allAirtable(limit: 50, filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressBlack: allAirtable(limit: 50, filter: { table: { eq: "dresses_black" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressWhite: allAirtable(limit: 50, filter: { table: { eq: "dresses_white" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }        
    dressPink: allAirtable(limit: 50, filter: { table: { eq: "dresses_pink" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressRed: allAirtable(limit: 50, filter: { table: { eq: "dresses_red" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressGreen: allAirtable(limit: 50, filter: { table: { eq: "dresses_green" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    # dressBridesmaid: allAirtable(limit: 50, filter: { table: { eq: "dress_bridesmaid" } }) {
    #   edges {
    #     node {
    #       data {
    #         company
    #         product_image
    #         product_title
    #         product_price
    #         product_link
    #         product_description
    #       }
    #     }
    #   }
    # }
    # dressSales: allAirtable(limit: 50, filter: { table: { eq: "dress_sales" } }) {
    #   edges {
    #     node {
    #       data {
    #         company
    #         product_image
    #         product_title
    #         product_price
    #         product_link
    #         product_description
    #       }
    #     }
    #   }
    # }
  }
`